import { makeStyles } from "tss-react/mui";

// erweiterte styles funktion, in der man mehr machen kann
export const useSubCategoryTemplateStyles = makeStyles()((theme) => {
  // console.log("text zum test", theme.palette.mode);
  return {
    wrapper: {},
    chipLink: {
      transition: "transform .15s ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
      },
      "&, & *": {
        cursor: "pointer",
      },
    },
  };
});
