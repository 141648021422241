import { Container, Grid, Link as MuiLink, Typography } from "@mui/material";
import { graphql, Link as GatsbyLink } from "gatsby";
import { useContext } from "react";
import { pathForCategory } from "../../../helpers/general/links/pathForCategory";
import { ColorModeContext } from "../../00-globals/GlobalWrapper/GlobalWrapper";
import { ChipLinkProps } from "../../01-atoms/ChipLink/ChipLinkInterfaces";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import { ChipStack } from "../../02-molecules/ChipStack/ChipStack";
import GridWithPosts from "../../03-organisms/GridWithPosts/GridWithPosts";
import { SubCategoryTemplateProps } from "./SubCategoryTemplateInterfaces";
import { useSubCategoryTemplateStyles } from "./SubCategoryTemplateStyles";

export const query = graphql`
  query ($id: String!) {
    posts: allWpPost(
      sort: { order: DESC, fields: date }
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      nodes {
        ...WpPostFields
      }
    }
    allCategories: allWpCategory {
      nodes {
        id
        name
        parentId
        slug
      }
    }

    allPostsWithSubCategoryFilter: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: "dGVybTo1Ng==" } } } }
      }
    ) {
      nodes {
        ...WpPostFields
      }
    }
  }
`;

const SubCategoryTemplate = (props: SubCategoryTemplateProps): JSX.Element => {
  const { data, pageContext } = props;
  const { classes } = useSubCategoryTemplateStyles();
  const allCategories = data.allCategories?.nodes || [];

  // Ergebnis vom Hook ist ein Objekt vom Typ "ColorModeData"
  const { toggleColorMode, colorMode } = useContext(ColorModeContext);

  const thisCategoryId = pageContext.id;

  const thisCategory = allCategories.find((category) => {
    return category.id === thisCategoryId;
  });

  const parentCategory = allCategories.find((category) => {
    return category.id === thisCategory?.parentId;
  });

  if (!thisCategory || !parentCategory) {
    throw Error(`Missing data for subcategory ${thisCategoryId}.`);
  }

  const subCategories = allCategories.filter((category) => {
    return category.parentId === thisCategory.parentId;
  });

  const articles = data.posts?.nodes || [];

  // "Umwelt" if I'm on "Plastic"
  const parentCategoryTitle = parentCategory.name;
  const parentCategorySlug = parentCategory.slug;

  const chipList: ChipLinkProps[] = subCategories.map((subCategory) => {
    const isActiveSubcategory = subCategory.id === thisCategory.id;

    return {
      label: subCategory.name,
      href: pathForCategory(subCategory),
      isActive: isActiveSubcategory,
    };
  });

  return (
    <Container className={classes.wrapper}>
      <SeoBasics
        description={`Hier findest du alle Artikel rund um das Thema ${parentCategoryTitle}`}
        title={`politikjam | ${parentCategoryTitle}`}
      />

      <Grid
        container={true}
        direction="column"
        alignItems="center"
        item={true}
        xs={12}
        mb={2.5}
        mt={2.5}
      >
        <MuiLink component={GatsbyLink} to={`/kategorie/${parentCategorySlug}`}>
          <Typography mb={2} variant="h1">
            {parentCategoryTitle}
          </Typography>
        </MuiLink>

        <ChipStack chips={chipList} />

        <GridWithPosts articlesPreviewData={articles} />
      </Grid>
    </Container>
  );
};

export default SubCategoryTemplate;
